<template>
    <div v-frag>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 hidden-sm hidden-xs"></div>
                <div class="col-md-6">
                    <form @submit.prevent="submit" method="post" class="form-invite">
                        <h3>{{ $t('header.invite-a-friend-h-web') }}</h3>
                        <br>
                        <div class="form-group registration">
                            <vue-tel-input 
                                v-model="phone"
                                :defaultCountry="chk"
                                mode="international"
                                :inputOptions="options"
                            ></vue-tel-input>
                        </div>
                        <div class="form-group">
                            <div class="">
                                <button type="submit" class="btn btn-default">{{ $t('header.invite-submit') }}</button>
                            </div>
                            
                        </div>
                    </form> 
                </div>
                <div class="col-md-4 hidden-sm hidden-xs"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import Auth from "@/services/auth";
    export default {
        name :'InviteFriend',
        data() {
            return {
                phone: '',
                options: {
                    placeholder: this.$i18n.t('header.place_phone')
                },
                loading: false
            }
        },
        computed: {
            ...mapGetters({
                user:'user',
                geo:'geo',
            }),
            chk() {
                if(this.user_ && this.user_.iso) {
                    return this.user_.iso;
                }
                else if(this.geo && this.geo.country_code2) {
                    return this.geo.country_code2;
                } else {
                    return "KE";
                }
            }
        },
        methods: {
            submit:  function() {   
                let str = this.phone;
                str = str.replace(/\s/g, "");
                this.phone = str.replace(/\+/g, "");

                Auth.inviteFriend(this.phone).then( (value) => {
                    if(value.success) {
                        this.$swal.fire(
                            this.$i18n.t('header.success-invitation-send'),
                            '',
                            'success'
                        );
                    } else {
                        this.$swal.fire(
                            this.$i18n.t('header.phone-number-already-invited'),
                            'Ooops..',
                            'error'
                        );
                    }
                    
                }).catch( err => console.log(err))             
            },
        }
    }
</script>
<style >
    
    .form-invite {
        padding-top: 50px;
        padding-bottom: 100px;
    }
    .vue-tel-input .vti__input {
        color : var(--bg-dark-medium)
    }
    .vue-tel-input .vti__dropdown-item {
        color : var(--bg-dark-medium)
    }
    .vue-tel-input .vti__dropdown {
        background-color: var(--a-text);
    }
    .vue-tel-input .vti__dropdown:hover {
        background-color: var(--a-text);
    }
    
</style>